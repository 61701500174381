import React from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';


import CommentsContainer from './CommentsContainer';

export default function CommentsDrawer({ target, setTarget, searchData, showComments, setShowComments }) {

    const handleClose = () => {
        setShowComments(false)
        setTarget(null)
    }


    return (
        <Drawer
            anchor='right'
            variant="persistent"
            open={showComments}
            style={{ zIndex: 100 }}
        >

            <Box style={{ margin: '25px 0 0 25px' }}>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>


            <Box
                id='right-drawer body'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '80vh',
                    width: '600px',
                    padding: '25px'
                }}
            >

                {/* <Button variant="contained" >Close</Button> */}
                <Typography variant="h5" style={{ margin: '15px' }}>
                    {target?.domain}
                </Typography>
                {
                    target && target['comments'] && (
                        <CommentsContainer searchData={searchData} target={target} />
                    )
                }

            </Box>
        </Drawer>
    )

}
