import React, { useState } from "react";

import {
  Box,
  Card,
  CardContent,
  Skeleton
} from "@mui/material";

import SortContainer from "../../components/SortContainer";
import TargetCard from "./TargetCard";



export default function TargetCardContainer({
  searchData,
  targets,
  setTargets,
  fetchTargetCount,
  loading,
}) {

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {targets?.length > 0 && (
        <SortContainer />
        )}
      </Box>

      <Box sx={{ my: 3 }}>
        {loading && <LoadingSkeleton />}
        {targets.slice(0, 100).map((target) => (
          <TargetCard
            searchData={searchData}
            key={target["domain"]}
            target={target} // id is on the result
            setTargets={setTargets}
            targets={targets}
            fetchTargetCount={fetchTargetCount}
          />
        ))}
      </Box>
    </Box>
  );
}

function LoadingSkeleton() {
  return (
    <Box sx={{width: 1400}}>
      {[...Array(5).keys()].map((i) => (
        <LoadingSkeletonCard key={i} />
      ))}
    </Box>
  );
}

function LoadingSkeletonCard() {
  return (
    <Card sx={{ mb: 4}}>
      <CardContent>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Skeleton
              variant="rectangular"
              width={200}
              height={40}
              sx={{ my: 1 }}
              style={{ borderRadius: "5px" }}
            />
            <Skeleton
              variant="rectangular"
              width={320}
              height={60}
              sx={{ my: 1 }}
              style={{ borderRadius: "5px" }}
            />
          </Box>

          <Skeleton
            variant="rectangular"
            width={420}
            height={60}
            sx={{ my: 1 }}
            style={{ borderRadius: "5px" }}
          />
        </Box>
        <Skeleton
          variant="rectangular"
          width="80%"
          height={300}
          style={{ borderRadius: "5px" }}
        />
      </CardContent>
    </Card>
  );
}
