import React, { useState } from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";


import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BusinessIcon from "@mui/icons-material/Business";
import CategoryIcon from "@mui/icons-material/Category";
import GoogleIcon from "@mui/icons-material/Google";
import HomeIcon from "@mui/icons-material/Home";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MapIcon from "@mui/icons-material/Map";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import PeopleIcon from "@mui/icons-material/People";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import StarIcon from "@mui/icons-material/Star";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

import { fromNow } from "../../../utils";

const PlaceChips = ({ target }) => (
  <Box id="google-place-chips">
    {target.meta &&
      target.meta.google_places &&
      Object.values(target.meta.google_places).map((place) => {
        return (
          <React.Fragment key={place["place_id"]}>
            <IconButton href={place["url"]} target="_blank">
              <GoogleIcon />
            </IconButton>
            <Chip
              icon={<StarIcon />}
              label={`${place["rating"]} stars (${place["user_ratings_total"]})`}
              variant="outlined"
              style={{ margin: "5px" }}
            />
            <Chip
              icon={<PlaceIcon />}
              label={place["formatted_address"]}
              variant="outlined"
              style={{ margin: "5px" }}
            />
            <Chip
              icon={<PhoneIcon />}
              label={place["formatted_phone_number"]}
              variant="outlined"
              style={{ margin: "5px" }}
            />
          </React.Fragment>
        );
      })}
  </Box>
);

export default function ChipRow({ searchData, target }) {
  const getColor = (key, val) => {
    const lowerVal = val.trim().toLowerCase();
    if (searchData.meta[key]) {
      let items = Array.isArray(searchData.meta[key])
        ? searchData.meta[key]
        : searchData.meta[key].split(",");
      const exists = items.some(
        (item) => item.trim().toLowerCase() === lowerVal
      );
      return exists ? "primary" : "default";
    } else {
      return "default";
    }
  };

  


  // Popper stuff
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const targetMeta = target["meta"];

  

  return (
    <Box
      id="chips"
      style={{
        margin: "10px 0",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {target["source"] && (
        <Chip
          icon={<AccountTreeIcon />}
          color="primary"
          label={target["source"]}
          variant="contained"
          style={{ margin: "5px" }}
        />
      )}
      {target["linkedin"] && (
        <IconButton href={target["linkedin"]} target="_blank">
          <LinkedInIcon />
        </IconButton>
      )}

      <PlaceChips target={target} />

      {target["meta"] && target["meta"]["headquarters"] && (
        <Chip
          icon={<HomeIcon />}
          label={target["meta"]["headquarters"]}
          variant="outlined"
          style={{ margin: "5px" }}
        />
      )}

      {target["geographies"] != "" &&
        target["geographies"]
          .split(",")
          .map((val, index) => (
            <Chip
              icon={<MapIcon />}
              label={val.trim()}
              key={index}
              color={
                searchData.meta["geographies"]?.includes(val.trim())
                  ? "secondary"
                  : "default"
              }
              style={{ margin: "5px" }}
            />
          ))}

      

      {target["employees"] != "" && target["employees"] > 0 && (
        <Chip
          icon={<PeopleIcon />}
          label={parseInt(target["employees"])}
          variant="outlined"
          style={{ margin: "5px", minWidth: "80px" }}
        />
      )}

      {target["ownership"] != "" && (
        <Chip
          icon={<BusinessIcon />}
          label={target["ownership"]}
          variant="outlined"
          style={{ margin: "5px" }}
        />
      )}

      {targetMeta["business_models"] != "" && (
        <Chip
          icon={<BusinessIcon />}
          label={targetMeta["business_models"]}
          variant="outlined"
          style={{ margin: "5px" }}
        />
        // target['meta']['business_models'].map((business_model) => {
        //     <Chip icon={<BusinessIcon />} label={business_model} variant="outlined" style={{ margin: '5px' }} />
        // })
      )}

      {target["products"] != "" &&
        target["products"]
          .split(",")
          .map((val, index) => (
            <Chip
              icon={<CategoryIcon />}
              label={val.trim()}
              key={index}
              color={
                searchData.meta["products"]?.includes(val.trim())
                  ? "secondary"
                  : "default"
              }
              style={{ margin: "5px" }}
            />
          ))}

      {target["services"] != "" &&
        target["services"]
          ?.split(",")
          .map((val, index) => (
            <Chip
              icon={<MiscellaneousServicesIcon />}
              label={val.trim()}
              key={index}
              variant="outlined"
              color={
                searchData.meta["services"]?.includes(val.trim())
                  ? "secondary"
                  : "default"
              }
              style={{ margin: "5px" }}
            />
          ))}

      {target["end_customer"] != "" &&
        target["end_customer"]
          .split(",")
          .map((val, index) => (
            <Chip
              icon={<PointOfSaleIcon />}
              label={val.trim()}
              key={index}
              color={
                searchData.meta["end_customer"]?.includes(val.trim())
                  ? "success"
                  : "default"
              }
              style={{ margin: "5px" }}
            />
          ))}

      <Chip
        icon={<WorkHistoryIcon />}
        label={`${target["change_date"]}`}
        variant="outlined"
        style={{ margin: "5px", minWidth: "80px" }}
      />
      <Chip
        icon={<AccessTimeIcon />}
        label={`${fromNow(target.updated)} by ${target["updated_by"]}`}
        variant="outlined"
        style={{ margin: "5px", minWidth: "80px" }}
      />

      

    
    </Box>
  );
}
