import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';

export default function OpenDealCloudContainer({ target }) {

    return (
        <Box>
            {

                target['dealcloud_id'] && target['dealcloud_id'] > 1 && (
                    <Button
                        href={`https://genz.dealcloud.com/portal/pages/3500/reports/64997/entries/${target['dealcloud_id']}`}
                        target="_blank"
                        color='warning'
                        startIcon={<LaunchIcon />}
                        style={{ marginTop: '15px' }}
                    >
                        Open Deal CRM
                    </Button>
                )
            }
        </Box>
    )


}