import axios from "axios";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  DataGridPremium,
  GridToolbar,
  LicenseInfo,
  useGridApiRef,
} from "@mui/x-data-grid-premium";

LicenseInfo.setLicenseKey(
  "230d663461398aa4ea65f86f5633e257Tz03OTE3MCxFPTE3MzIyMTc2NzcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

import {
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Link,
  MenuItem,
  Rating,
  TextField,
} from "@mui/material";

import ForumIcon from "@mui/icons-material/Forum";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MoveDownIcon from "@mui/icons-material/MoveDown";

import EditTextarea from "../../components/EditTextArea";
import CommentsDrawer from "./CommentsDrawer.js";

import { API_BASE } from "../../App.js";
import { ICON_MAP, LABEL_MAP } from "../../fragments/Constants";
import { fromNow } from "../../utils";

export default function TargetTableContainer({
  searchData,
  targets,
  setTargets,
  fetchTargetCount,
}) {
  const currentView = useSearchParams()[0].get("currentView");

  const [showComments, setShowComments] = React.useState(false);
  const [target, setTarget] = React.useState(null);

  const [moveTo, setMoveTo] = useState(currentView);

  const apiRef = useGridApiRef();

  const handleCommentClick = async (row) => {
    setShowComments(!showComments);
    setTarget(row);
  };

  const handleUpdate = async (newRow, oldRow) => {
    const findChanges = (newValue, oldValue) => {
      const changes = {};
      for (const [key, value] of Object.entries(newValue)) {
        if (oldValue[key] !== value) {
          changes[key] = value;
        }
      }
      return changes;
    };
    const endpoint = API_BASE + "event";
    const response = await axios.post(endpoint, {
      search_uid: searchData.uid,
      domain: newRow.domain,
      type: "update",
      data: findChanges(newRow, oldRow),
      actor_key: localStorage.getItem("actorKey"),
      token: localStorage.getItem("accessToken"),
    });
    console.log(newRow, oldRow);

    return newRow;
  };

  const handleMoveSelectedTargets = async () => {
    const selected = apiRef.current.getSelectedRows();
    const domains = [...selected.keys()]; // Spread into a new array if necessary

    const endpoint = `${API_BASE}event`;
    const response = await axios.post(endpoint, {
      search_uid: searchData.uid,
      type: "move",
      actor_key: localStorage.getItem("actorKey"),
      token: localStorage.getItem("accessToken"),
      data: {
        domains: domains,
        stage: moveTo,
      },
    });

    // want to refresh the count

    // setTargets(targets.filter((t) => !domains.includes(t.domain)));
    await new Promise((r) => setTimeout(r, 1000));
    // fetchTargetCount();
    window.location.reload();
  };

  const createCustomColumns = (customColumns) => {
    return customColumns.map((columnName) => ({
      field: columnName,
      width: 80,
      editable: true,
      type: "string", // Default to 'string' if type isn't specified
      valueGetter: (params) => {
        return params.row.hasOwnProperty(columnName)
          ? params.row[columnName]
          : (params.row.meta && params.row.meta[columnName]) || "";
      },
    }));
  };

  const handleRating = async (event, newValue, target) => {
    // console.log("omglol", newValue, target)
    event.preventDefault();
    event.stopPropagation();

    const starRating = newValue;
    const endpoint = API_BASE + "event";
    const response = axios.post(endpoint, {
      search_uid: target["search_uid"],
      domain: target["domain"],
      type: "rating",
      actor_key: localStorage.getItem("actorKey"),
      token: localStorage.getItem("accessToken"),
      data: {
        rating: newValue,
        currentView: currentView,
      },
    });
    setTargets(targets.filter((t) => t.domain != target["domain"]));
    await new Promise((r) => setTimeout(r, 2000));
    fetchTargetCount();
  };

  const customColumns = searchData?.meta.custom_columns
    ? createCustomColumns(searchData.meta.custom_columns)
    : [];

  const predefinedColumns = [
    {
      field: "comments",
      headerName: "",
      width: 60,
      renderCell: ({ row }) => (
        <Box>
          <IconButton onClick={() => handleCommentClick(row)}>
            <Badge
              badgeContent={row.comments?.length}
              color="warning"
              sx={{
                "& .MuiBadge-badge": { fontSize: 10, height: 15, minWidth: 15 },
              }}
            >
              <ForumIcon fontSize="small" color="info" />
            </Badge>
          </IconButton>
        </Box>
      ),
    },
    {
      field: "rating",
      headerName: "stars",
      width: 150,
      type: "number",
      valueGetter: ({ row }) => {
        return row.rating;
      },
      renderCell: (params) => (
        <Box>
          <Rating
            sx={{ zIndex: 1000, p: 1 }}
            value={params.value}
            onChange={(event, newValue) =>
              handleRating(event, newValue, params.row)
            }
          />
        </Box>
      ),
    },
    {
      field: "employees",
      type: "number",
      width: 100,
      editable: true,
    },
    {
      field: "source",
      editable: true,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value.split(",").map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                // color='primary' if val in searchData.meta['products']
                color="primary"
                size="small"
                style={{ margin: "0 5px 5px 0" }}
              />
            ))}
        </Box>
      ),
    },
    {
      field: "change_date",
    },

    {
      field: "linkedin",
      headerName: "",
      editable: true,
      width: 50,
      renderCell: ({ value }) =>
        value ? (
          <IconButton href={value} target="_blank">
            <LinkedInIcon style={{ color: "lightgray" }} />
          </IconButton>
        ) : null,
    },
    {
      field: "domain",
      // width: 170,
      renderCell: ({ value }) => (
        <Link
          href={`https://www.${value}`}
          target="_blank"
          underline="hover"
          color="inherit"
        >
          {value}
        </Link>
      ),
    },
    {
      field: "gpt_description",
      width: 200,
      editable: true,
      type: "string",
      renderEditCell: (params) => <EditTextarea {...params} />,
    },

    {
      field: "products",
      // width: 250,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value.split(",").map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                // color='primary' if val in searchData.meta['products']
                color={
                  searchData.meta["products"]?.includes(val.trim())
                    ? "primary"
                    : "default"
                }
                size="small"
                style={{ margin: "0 5px 5px 0" }}
              />
            ))}
        </Box>
      ),
    },
    {
      field: "services",
      // width: 250,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  color={
                    searchData.meta["services"]?.includes(val.trim())
                      ? "success"
                      : "default"
                  }
                  style={{ margin: "0 5px 5px 0" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "end_customer",
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  color={
                    searchData.meta["end_customer"]?.includes(val.trim())
                      ? "primary"
                      : "default"
                  }
                  style={{ margin: "0 5px 5px 0" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "geographies",
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  color={
                    searchData.meta["geographies"]?.includes(val.trim())
                      ? "secondary"
                      : "default"
                  }
                  style={{ margin: "0 5px 5px 0" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "name",
      editable: true,
    },

    {
      field: "ownership",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "Private",
        "Private Equity",
        "Public",
        "Venture Capital",
        "Other",
      ],
      // renderCell: ({ value }) => (
      //   <Box>
      //     <Chip
      //       label={value}
      //       size='small'
      //     />
      //   </Box>
      // ),
    },

    {
      field: "business_models",
      headerName: "business_type",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Manufacturer", "Distributor", "Services"],
      renderCell: ({ value }) => value,
    },
    {
      field: "city",
      width: 90,
      editable: true,
      // valueGetter: ({ row }) => {
      //   return row.city || (row.headquarters ? row.headquarters.split(', ')[0] : '') || '';
      // }
    },
    {
      field: "state",
      width: 80,
      editable: true,
      // type: 'singleSelect',
      // valueOptions: STATES,
      // valueGetter: ({ row }) => {
      //   return row.state || (row.headquarters ? row.headquarters.split(', ')[1] : '') || '';
      // }
    },
    {
      field: "contact_name",
      editable: true,
    },
    {
      field: "contact_title",
      editable: true,
    },
    {
      field: "contact_email",
      editable: true,
    },
    {
      field: "contact_phone",
      editable: true,
    },
    {
      field: "contact_address",
      editable: true,
    },
    {
      field: "was_acquired",
      width: 100,
      editable: true,
    },
    {
      field: "justification",
      width: 100,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
    },
    {
      field: "year_founded",
      headerName: "founded",
      type: "number",
      width: 80,
      editable: true,
    },
    {
      field: "designation",
      width: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Minority-Owned", "Woman-Owned", "Other", "None"],
    },

    {
      field: "updated",
      width: 120,
      renderCell: ({ row }) => {
        if (!row.updated) return "";
        return fromNow(row.updated);
      },
    },
    {
      field: "updated_by",
      width: 120,
    },
    {
      field: "industry",
      editable: true,
    },
    {
      field: "linkedin_range",
      editable: true,
    },
    {
      field: "revenue_estimates",
      editable: true,
    },
    {
      field: "location_count",
      // headerName: 'locations',
      type: "number",
      editable: true,
    },
    {
      field: "facility_size",
      editable: true,
    },
    // {
    //   field: "primary_contact",
    //   editable: true,
    // },
  ];

  const columns = [...customColumns, ...predefinedColumns];

  const setColumnOrder = (searchUid, columnOrder) => {
    localStorage.setItem(searchUid, JSON.stringify(columnOrder));
  };

  const handleColumnOrderChange = () => {
    const columnOrder = apiRef.current
      .getAllColumns()
      .map((column) => column.field);
    console.log(columnOrder);
    setColumnOrder(searchData.uid, columnOrder);
  };

  const getColumnOrder = () => {
    const columnOrder = localStorage.getItem(searchData.uid);
    return columnOrder ? JSON.parse(columnOrder) : columns.map((c) => c.field);
  };

  const orderedColumns = getColumnOrder().map(
    (field) => columns.find((c) => c.field === field) || { field }
  );

  const handleFilterChange = (filters) => {
    localStorage.setItem(searchData.uid + "_filters", JSON.stringify(filters));
  };

  const getFilter = () => {
    const filters = localStorage.getItem(searchData.uid + "_filters");
    return filters ? JSON.parse(filters) : {"items": []};
  }

  function getInitialState() {
    const defaultInitialState = {
      pagination: { paginationModel: { pageSize: 10 } },
      sorting: { sortModel: [{ field: "employees", sort: "desc" }] },
      columns: {
        columnVisibilityModel: {
          ownership: false,
          designation: false,
          updated: false,
          updated_by: false,
        },
      },
      filter: { filterModel: getFilter() },
    };
    return defaultInitialState;
  }

  return (
    <Box sx={{ width: "100%", mb: 25 }}>
      <CommentsDrawer
        showComments={showComments}
        setShowComments={setShowComments}
        searchData={searchData}
        target={target}
        setTarget={setTarget}
      />

      {true && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
          <TextField
            select
            label="Move to"
            value={moveTo}
            onChange={(e) => setMoveTo(e.target.value)}
            style={{ minWidth: "120px" }}
            size="small"
          >
            {Object.keys(LABEL_MAP).map((key) => (
              <MenuItem key={key} value={key}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                  {ICON_MAP[key]}
                  {LABEL_MAP[key]}
                </Box>
              </MenuItem>
            ))}
          </TextField>

          <Button
            variant="outlined"
            color="warning"
            onClick={handleMoveSelectedTargets}
            startIcon={<MoveDownIcon />}
          >
            Move Selected
          </Button>
        </Box>
      )}

      {searchData && (
        <Box>
          <DataGridPremium
            apiRef={apiRef}
            rows={targets}
            columns={orderedColumns}
            getRowId={(row) => row.domain}
            density="compact"
            processRowUpdate={handleUpdate}
            onProcessRowUpdateError={(error, row, field, newValue) => {
              console.log(error, row, field, newValue);
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            // selectionModel={selectionModel}
            pagination
            initialState={getInitialState()}
            pageSizeOptions={[10, 20, 50, 100]}
            unstable_cellSelection
            experimentalFeatures={{ clipboardPaste: true }}
            unstable_ignoreValueFormatterDuringExport
            checkboxSelection
            onColumnOrderChange={handleColumnOrderChange}
            onFilterModelChange={handleFilterChange}
            // filterModel={getFilter()}
          />
        </Box>
      )}
    </Box>
  );
}
