import axios from "axios";
import React, { useEffect, useState } from "react";

import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

import { Box, Skeleton, Typography } from "@mui/material";

import { API_BASE } from "../../App.js";
import { DataUsage, Whatshot } from "@mui/icons-material";

export default function ReportingSearches({}) {
  const [searches, setSearches] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSearches = async (e) => {
    try {
      setLoading(true);
      const endpoint = API_BASE + "search_target_count_by_stage";
      const response = await axios.get(endpoint, {
        params: {
          actor_key: localStorage.getItem("actorKey"),
          token: localStorage.getItem("accessToken"),
        },
      });
      const searches = response["data"]["searches"];
      setSearches(searches);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(async () => {
    getSearches();
  }, []);

  const columns = [
    {
      field: "analyst",
      width: 150,
    },
    {
      field: "label",
      headerName: "Search",
      width: 250,
    },
    {
      field: "search_status",
      headerName: "status",
      type: "string",
      width: 120,
    },
    {
      field: "Landing",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Inbox",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Similar",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Review",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Hold",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Validated",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Client Inbox",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Client Approved",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Synced",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Reject",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Conflict",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Client Conflict",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Client Reject",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "Buyer",
      type: "number",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
  ];

  return (
    <Box
      sx={{
        px: 4,
        my: 4,
        "& .number-is-zero": {
          color: "#4E4B4B",
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <DataUsage color="auto" sx={{ fontSize: 24 }} />
        <Typography variant="h5" sx={{ my: 2 }}>
          Target Count by Search Stage
        </Typography>
      </Box>

      {loading && (
        <Skeleton
          variant="rectangular"
          height={360}
          style={{ borderRadius: "5px" }}
        />
      )}

      {!loading && (
        <DataGridPremium
          rows={searches}
          columns={columns}
          getRowId={(row) => row.label}
          density="compact"
          autoHeight
          slots={{
            toolbar: GridToolbar,
          }}
          pagination
          pageSizeOptions={[5, 10, 20, 50, 100]}
          unstable_cellSelection
          unstable_ignoreValueFormatterDuringExport
          sx={{
            "& .MuiDataGrid-aggregationColumnHeaderLabel": {
              color: "white",
              opacity: 0.6,
              fontSize: "10px",
            },
            "& .MuiDataGrid-aggregationColumnHeader": {
              minHeight: "50px",
            },
            "& .MuiDataGrid-footerCell": {
              color: "secondary.main",
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
            sorting: { sortModel: [{ field: "Review", sort: "desc" }] },
            filter: {
              filterModel: {
                items: [
                  {
                    field: "search_status",
                    operator: "equals",
                    value: "active",
                  },
                ],
              },
            },
            columns: {
              columnVisibilityModel: {
                Conflict: false,
                "Client Conflict": false,
                "Client Reject": false,
              },
            },
            aggregation: {
              model: {
                Landing: "sum",
                Inbox: "sum",
                Similar: "sum",
                Review: "sum",
                Hold: "sum",
                Validated: "sum",
                "Client Inbox": "sum",
                "Client Approved": "sum",
                Synced: "sum",
                Reject: "sum",
                Conflict: "sum",
                "Client Conflict": "sum",
                "Client Reject": "sum",
                Buyer: "sum",
              },
            },
          }}
        />
      )}
    </Box>
  );
}
