import InboxIcon from "@mui/icons-material/Inbox";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SyncIcon from "@mui/icons-material/Sync";
import FlagIcon from "@mui/icons-material/Flag";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ParaglidingIcon from "@mui/icons-material/Paragliding";
// import BackHandIcon from "@mui/icons-material/BackHand";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

export const SOURCE_OPTIONS = [
  "Client",
  "Conference",
  "Organic Research",
  "Grata",
  "Cyndx",
  "Sourcescrub",
  "Google",
  "Idea",
  "Similar Search",
];


export const LABEL_MAP = {
  land: "Landing",
  create: "Inbox",
  similar: "Similar",
  advance: "Review",
  hold: "Hold",
  validate: "Validated",
  send: "Client Inbox",
  client_approve: "Client Approved",
  sync: "Synced",
  reject: "Reject",
  conflict: "Conflict",
  client_conflict: "Client Conflict",
  client_reject: "Client Reject",
  buyer: "Buyer",
};

export const ICON_MAP = {
  land: <ParaglidingIcon />,
  create: <InboxIcon />,
  similar: <AutoAwesomeIcon />,
  advance: <ThumbUpIcon />,
  hold: <StopCircleIcon />,
  validate: <VerifiedUserIcon />,
  send: <MailOutlineIcon />,
  client_approve: <CheckCircleIcon />,
  sync: <SyncIcon />,
  reject: <RemoveCircleOutlineOutlinedIcon />,
  client_reject: <RemoveCircleOutlinedIcon />,
  conflict: <FlagOutlinedIcon />,
  client_conflict: <FlagIcon />,
  buyer: <AccountBalanceIcon />,
};

export const OWNERSHIP = [
  "bootstrapped",
  "investor_backed",
  "public",
  "public_subsidiary",
  "private_subsidiary",
  "private_equity",
  "private_equity_add_on",
];

export const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const COUNTRIES = ["USA", "MEX", "CAN", "GBR"];

// ugh


