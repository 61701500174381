import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import React from "react";

import { ICON_MAP, LABEL_MAP } from "../../fragments/Constants";

export default function LeftDrawer({
  searchData,
  currentView,
  targetCount,
  open,
}) {
  const setView = async (name) => {
    window.location.href = `/search?searchKey=${searchData["uid"]}&currentView=${name}`;
  };

  return (
    <Box
      id="left-drawer-main"
      sx={{
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
        width: 150,
      }}
    >
      <List>
        {Object.keys(LABEL_MAP).map((key) => (
          <ListItem key={key}>
            <Tooltip title={LABEL_MAP[key]} placement="right-start">
              <ListItemButton
                onClick={() => setView(key)}
                selected={currentView === key}
              >
                <ListItemIcon>{ICON_MAP[key]}</ListItemIcon>
                <ListItemText primary={targetCount[key]} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
