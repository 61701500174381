import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Slider,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { COUNTRIES, OWNERSHIP, STATES } from "../../fragments/Constants";
import { API_BASE } from "../../App.js";
import AutoAwesome from "@mui/icons-material/AutoAwesome.js";

export default function GrataDrawer({ open, setOpen }) {
  const searchUid = useSearchParams()[0].get("searchKey");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [include, setInclude] = useState([]);
  const [exclude, setExclude] = useState([]);
  const [excludeStates, setExcludeStates] = useState([]);
  const [op, setOp] = useState("any");
  const [ownership, setOwnership] = useState([]);
  // const [employeesRange, setEmployeesRange] = useState([0, 0]); // grata_employees_estimates_range
  const [minEmployees, setMinEmployees] = useState(10);
  const [maxEmployees, setMaxEmployees] = useState(500);

  const [countries, setCountries] = useState(["USA"]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  // const [headquarters, setHeadquarters] = useState({});

  const [resultCount, setResultCount] = useState(100);

  const fetchCriteriaEvent = async () => {
    try {
      const endpoint = API_BASE + "search_criteria";
      const eventResponse = await axios.get(endpoint, {
        params: {
          search_uid: searchUid,
        },
      });
      const criteria = eventResponse.data;


      setInclude(criteria.inclusion.keywords);
      setOwnership(criteria.inclusion.ownership);
      setMinEmployees(criteria.inclusion.employees_range[0]);
      setMaxEmployees(criteria.inclusion.employees_range[1]);
      setCountries(criteria.inclusion.country);
      setStates(criteria.inclusion.state);
      setCities(criteria.inclusion.city);

      setExclude(criteria.exclusion.keywords);
      setExcludeStates(criteria.exclusion.state);

      setOp(criteria.operator);
      setResultCount(criteria.result_count);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCriteriaEvent();
  }, [searchUid]);

  const runCriteriaSearch = async () => {
    try {
      setLoading(true);

      const endpoint = API_BASE + "event";
      const eventResponse = await axios.post(endpoint, {
        search_uid: searchUid,
        actor_key: localStorage.getItem("actorKey"),
        type: "criteria",
        data: {
          inclusion: {
            keywords: include,
            ownership: ownership,
            employees_range: [minEmployees, maxEmployees],
            country: countries,
            state: states,
            city: cities,
          },
          exclusion: {
            keywords: exclude,
            state: excludeStates,
          },
          operator: op,
          result_count: resultCount,
        },
      });
      await new Promise((r) => setTimeout(r, 5000));
      window.location.href = `/search?searchKey=${searchUid}&currentView=land`;
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  // const runCriteriaSearch = async () => {
  //   console.log("running search");
  // }

  const handleStatesChange = (event, v) => {
    if (cities.length > 0 && v.length > 1) {
      alert("Please select only one state when selecting cities");
      return;
    }
    // setInclusion({
    //   ...inclusion,
    //   state: v,
    //   country: ["USA"],
    // });
    setStates(v);
    setCountries(["USA"]);
  };

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={open}
      style={{ zIndex: 64 }}
    >
      <AppBar position="static" variant="transparent">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h6" component="div">
              Grata+ Search
            </Typography>
          </Box>

          <Button
            variant="contained"
            onClick={runCriteriaSearch}
            style={{ borderRadius: "20px" }}
            startIcon={<AutoAwesome />}
            disabled={loading}
          >
            Go
          </Button>
        </Toolbar>
      </AppBar>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: 500
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box id="main-drawer-body" sx={{ px: 4, width: 500 }}>
          <Typography variant="caption" color="darkgrey">
            Results delivered to Inbox, Filters applied to Similiarity Search
          </Typography>
          <>
            <Box id="keywords" sx={{ mt: 4 }}>
              <Autocomplete
                id="include-keywords-operator"
                options={["any", "all"]}
                value={op}
                onChange={(e, v) => setOp(v)}
                renderInput={(params) => (
                  <TextField {...params} label="Keywords Operator" />
                )}
                style={{ marginBottom: "15px" }}
              />

              <Autocomplete
                multiple
                id="inclusion-keywords"
                options={[]}
                value={include}
                onChange={(e, v) => setInclude(v)}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} label="Keywords" />
                )}
                freeSolo
                style={{ marginBottom: "15px" }}
              />
            </Box>
            <Box id="structural">
              <Autocomplete
                multiple
                id="inclusion-ownership"
                options={OWNERSHIP}
                value={ownership}
                onChange={(e, v) => setOwnership(v)}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} label="Ownership" />
                )}
                style={{ marginBottom: "15px" }}
              />

              <Box style={{ display: "flex" }}>
                <TextField
                  label="Min Employees"
                  type="number"
                  value={minEmployees}
                  onChange={(e) => setMinEmployees(parseInt(e.target.value))}
                  style={{ margin: "0 15px 15px 0" }}
                />

                <TextField
                  label="Max Employees"
                  type="number"
                  value={maxEmployees}
                  onChange={(e) => setMaxEmployees(parseInt(e.target.value))}
                  style={{ margin: "0 0 15px 10px" }}
                />
              </Box>
            </Box>
            <Box id="geographic">
              <Autocomplete
                disabled={states.length > 1}
                multiple
                id="tags-outlined"
                options={COUNTRIES}
                value={countries}
                onChange={(e, v) => setCountries(v)}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} label="Countries" />
                )}
                style={{ marginBottom: "15px" }}
              />

              <Autocomplete
                multiple
                id="tags-outlined"
                options={STATES}
                value={states}
                onChange={handleStatesChange}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} label="State(s)" />
                )}
                style={{ marginBottom: "15px" }}
              />

              {states.length < 2 && (
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={["San Diego"]}
                  value={cities}
                  onChange={(e, v) => setCities(v)}
                  filterSelectedOptions
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="Cities" />
                  )}
                  style={{ marginBottom: "15px" }}
                />
              )}
            </Box>
          </>

          <Box id="exclusion">
            <Autocomplete
              multiple
              id="exclude-keyword"
              options={[]}
              value={exclude}
              onChange={(e, v) => setExclude(v)}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="Exclude Keywords" />
              )}
              freeSolo
              style={{ marginBottom: "15px" }}
            />

            <Autocomplete
              multiple
              id="exlcusion-states"
              options={STATES}
              value={excludeStates}
              onChange={(e, v) => setExcludeStates(v)}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="Exclude States" />
              )}
              style={{ marginBottom: "15px" }}
            />

            <Typography variant="caption" color="darkgrey">
              Result Count:
            </Typography>
            <Slider
              value={resultCount}
              onChange={(e, v) => setResultCount(v)}
              min={0}
              max={100}
              step={25}
              valueLabelDisplay="auto"
              style={{ margin: "0 0 15px 10px" }}
            />
          </Box>
        </Box>
      )}
    </Drawer>
  );
}

// function PageAppBar({ open, setOpen, runCriteriaSearch }) {
//   return (

//   );
// }
