import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';


import { API_BASE } from '../../App';


export default function CreateSearchContainer({ open, setOpen }) {

    const [loading, setLoading] = useState(false)
    const [uid, setUid] = useState("")
    const [label, setLabel] = useState("")
    const [prompt, setPrompt] = useState("")
    const [error, setError] = useState(null)

    const navigate = useNavigate()

    const insertSearch = async () => {
        try {
            setLoading(true)
            const endpoint = API_BASE + 'search'
            const repsonse = await axios.post(endpoint, {
                "uid": uid,
                "label": label,
                "meta": {
                    "prompt": prompt,
                },
                "actor_key": localStorage.getItem("actorKey"),
                "token": localStorage.getItem("accessToken"),

            });
            // this will get thrown off
            setLoading(false)
            // window.location.reload()
            navigate(`/search?searchKey=${uid}&currentView=land`)
        } catch (error) {
            console.log(error)
            setError(error.message)
        } finally {
            setLoading(false)
            
        }

    }

    return (
        <Box style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        }}>

            {
                open && (
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '25px',
                        maxWidth: '700px'
                    }}>
                        <Button
                            startIcon={<ClearIcon />}
                            onClick={() => setOpen(false)}
                            variant='outlined'
                            color='info'
                            style={{ marginBottom: "15px" }}
                        >
                            Cancel
                        </Button>

                        <TextField
                            value={uid}
                            onChange={(e) => setUid(e.target.value)} // or, url 
                            disabled={loading}
                            helperText="or, dealcloud_id directly"
                            label="dealcloud engagement url"
                            autoComplete='off'
                            variant='outlined'
                            style={{ marginBottom: '10px', width: '400px' }}

                        />
                        <TextField
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                            disabled={loading}
                            helperText="label for this search"
                            label="label"
                            autoComplete='off'
                            variant='outlined'
                            style={{ marginBottom: '10px' }}
                        />
                        {/* <TextField
                            value={setPrompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            disabled={loading}
                            helperText="set the context for this search"
                            label="prompt"
                            autoComplete='off'
                            variant='outlined'
                            rows={4}
                            multiline
                            style={{ marginBottom: '10px' }}
                        /> */}

                        <Button
                            variant='contained'
                            fullWidth
                            onClick={insertSearch}
                            style={{ marginTop: '15px' }}
                        >
                            Create Search
                        </Button>
                    </Box>
                )
            }
        </Box>
    );
}

