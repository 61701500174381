import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

import { API_BASE } from "../../../App";

export default function RatingContainer({
  target,
  targets,
  setTargets,
  setDisplay,
  fetchTargetCount,
}) {
  // NB: pass the rating, setRating so that the validate button can be disabled if no rating is set
  const currentView = useSearchParams()[0].get("currentView")
  
  const [ratingHover, setRatingHover] = React.useState(-1);
  const [rating, setRating] = useState(target.rating);

  const ratingLabels = {
    1: "Reject",
    2: "Hold",
    3: "Maybe",
    4: "Good Fit",
    5: "Perfect Fit",
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${ratingLabels[value]}`;
  }

  const handleRating = async (e, newValue) => {
    // nothing is newValue is null
    if (newValue === null) {
      return;
    }


    const endpoint = API_BASE + "event";
    const response = axios.post(endpoint, {
      search_uid: target["search_uid"],
      domain: target["domain"],
      id: target["id"],
      type: "rating",
      actor_key: localStorage.getItem("actorKey"),
      token: localStorage.getItem("accessToken"),
      data: {
        rating: newValue,
        currentView: currentView,
      },
    });

    setRating(newValue);

    setTargets(targets.filter((t) => t.domain !== target.domain));
    await new Promise((r) => setTimeout(r, 3000));
    fetchTargetCount();

  };

  return (
    <Box
      id="rating-container"
      style={{ marginLeft: "5px", display: "flex", flexDirection: "row" }}
    >
      <Rating
        value={rating}
        onChange={handleRating}
        getLabelText={getLabelText}
        onChangeActive={(event, newHover) => {
          setRatingHover(newHover);
        }}
      />
      <Box id="hover-label-container">
        <Box sx={{ ml: 2 }}>
          {ratingLabels[ratingHover !== -1 ? ratingHover : rating]}
        </Box>
      </Box>
    </Box>
  );
}
