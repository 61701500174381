import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function SortContainer({  }) {

    const [loading, setLoading] = useState(false)
    const [editing, setEditing] = useState(false)

    const SORT_OPTIONS = [
        'updated',
        'source', 
        'domain', 
        'name', 
        'rating', 
        'employees', 
        'ownership'
    ]

    const [sortField, setSortField] = useState(localStorage.getItem('sortField') || 'employees')
    const [sortOrder, setSortOrder] = useState(localStorage.getItem('sortOrder') || 'desc')
   
    const saveSearchSort = () => {
        localStorage.setItem('sortField', sortField)
        localStorage.setItem('sortOrder', sortOrder)
        setEditing(false)
        // getTargets()
        window.location.reload()
    }

    return (
        <Box>
            <Box id='sort-header' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    {!editing && (
                        <Chip
                            label={sortField}
                            variant="outlined"
                            deleteIcon={<EditIcon />}
                            onDelete={() => setEditing(!editing)}
                            // color='info'
                            icon={sortOrder == 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                            style={{ margin: '0 15px 5px 0' }}
                        />
                    )}
                </Box>

                {
                    editing && (
                        <Button
                            endIcon={<ClearIcon />}
                            color='darkgray'
                            onClick={() => setEditing(!editing)}
                        />
                    )
                }


            </Box>

            <Box>
                {loading && (<Box style={{ width: '100%' }}><CircularProgress /></Box>)}

                {editing && (
                    <Box>
                        <TextField
                            label="Sort Field"
                            value={sortField}
                            select
                            fullWidth
                            style={{ marginBottom: '20px' }}
                            onChange={(e) => { setSortField(e.target.value) }}
                        >
                            {
                                SORT_OPTIONS.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                        <TextField
                            label="Sort Order"
                            value={sortOrder}
                            select
                            fullWidth
                            style={{ marginBottom: '20px' }}
                            onChange={(e) => { setSortOrder(e.target.value) }}
                        >
                            {
                                ['asc', 'desc'].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                        <Button
                            onClick={saveSearchSort}
                            variant='contained'
                            fullWidth
                            style={{ marginBottom: '15px' }}
                        >
                            Save
                        </Button>
                    </Box>
                )}

            </Box>

        </Box>
    )


}