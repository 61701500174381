import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid-premium";

import { Box, Typography, Link, Chip, IconButton, Badge } from "@mui/material";

import ForumIcon from "@mui/icons-material/Forum";
import LaunchIcon from "@mui/icons-material/Launch";

import SearchCommentsDrawer from "./SearchCommentsDrawer";
import EditTextarea from "../../components/EditTextArea";

import { fromNow } from "../../utils";
import { API_BASE } from "../../App.js";
import { Whatshot } from "@mui/icons-material";

export default function SearchesTable({ searches }) {
  const getWeekColor = (weekChar) => {
    const currentWeekNumber = Math.ceil(
      (new Date().getTime() -
        new Date(new Date().getFullYear(), 0, 1).getTime()) /
        (1000 * 60 * 60 * 24 * 7)
    );
    const currentWeekChar = currentWeekNumber % 2 === 0 ? "A" : "B";
    return currentWeekChar === weekChar ? "primary" : "info";
  };

  const handleUpdate = async (newRow, oldRow) => {
    // console.log(newRow)
    const findChanges = (newValue, oldValue) => {
      const changes = {};
      for (const [key, value] of Object.entries(newValue)) {
        if (oldValue[key] !== value) {
          changes[key] = value;
        }
      }
      return changes;
    };
    const endpoint = API_BASE + "event";
    const response = await axios.post(endpoint, {
      search_uid: newRow.uid,
      type: "update",
      data: findChanges(newRow, oldRow),
      actor_key: localStorage.getItem("actorKey"),
      token: localStorage.getItem("accessToken"),
    });

    return newRow;
  };

  const [showComments, setShowComments] = useState(false);
  const [search, setSearch] = useState(null);

  const handleCommentClick = async (row) => {
    setShowComments(!showComments);
    setSearch(row);
  };

  const columns = [
    {
      field: "analyst",
    },
    {
      field: "client",
    },
    {
      field: "week",
      editable: true,
      width: 60,
      type: "singleSelect",
      valueOptions: ["A", "B"],
      renderCell: ({ row }) =>
        row.week && (
          <Chip label={row.week} size="small" color={getWeekColor(row.week)} />
        ),
    },
    {
      field: "day",
      editable: true,
      width: 80,
      type: "singleSelect",
      valueOptions: ["1-Mon.", "2-Tue.", "3-Wed.", "4-Thu.", "5-Fri."],
    },
    {
      field: "label",
      width: 200,
      headerName: "search",
      renderCell: ({ row }) => (
        <Link
          href={`/search?searchKey=${row.uid}&currentView=land`}
          style={{
            cursor: "pointer",
            textDecoration: "none",
            textTransform: "uppercases",
            color: "white",
          }}
        >
          {row.label}
        </Link>
      ),
    },
    {
      field: "last_1_days",
      headerName: "1d",
      type: "number",
      width: 60,
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "last_7_days",
      headerName: "7d",
      type: "number",
      width: 60,
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "last_30_days",
      headerName: "30d",
      type: "number",
      width: 60,
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "last_90_days",
      headerName: "90d",
      type: "number",
      width: 90,
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },

    {
      field: "total_validations",
      headerName: "total",
      type: "number",
      width: 60,
    },
    {
      field: "total_validations_pct",
      headerName: "pct",
      type: "number",
      width: 60,
    },
    {
      field: "search_status",
      headerName: "status",
      width: 120,
      editable: true,
      type: "singleSelect",
      // filterable: true,
      valueOptions: [
        "starting",
        "active",
        "holding",
        "blocked",
        "completed",
        "paused",
        "cancelled",
      ],
    },
    {
      field: "type",
      headerName: "search_type",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Platform", "Add-On", "Market Map"],
    },

    {
      field: "next_due_date",
      headerName: "next_call",
      width: 100,
      editable: true,
      type: "date",
      valueGetter: ({ row }) => {
        return row.next_due_date ? new Date(row.next_due_date) : null;
      },
    },
    {
      field: "from_now",
      headerName: "",
      width: 80,
      valueGetter: ({ row }) => {
        if (!row.next_due_date) return "";
        return fromNow(row.next_due_date);
      },
      renderCell: ({ row }) => {
        if (!row.next_due_date) return "";
        return fromNow(row.next_due_date);
      },
    },

    {
      field: "products",
      width: 130,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  color="primary"
                  style={{ marginRight: "5px" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "services",
      width: 130,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  color="success"
                  size="small"
                  style={{ marginRight: "5px" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "end_customer",
      width: 130,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  color="secondary"
                  style={{ marginRight: "5px" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "customers",
      width: 130,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  color="secondary"
                  style={{ marginRight: "5px" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "geographies",
      width: 130,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  color="info"
                  style={{ marginRight: "5px" }}
                />
              ))}
        </Box>
      ),
    },

    {
      field: "rating",
      width: 80,
      valueGetter: ({ row }) => {
        return row.rating_avg ? parseFloat(row.rating_avg).toFixed(1) : null;
      },
      renderCell: ({ row }) => (
        <Box>
          {row.rating_avg ? parseFloat(row.rating_avg).toFixed(1) : null}
          {row.rating_avg && (
            <span
              style={{ fontSize: "0.8em", color: "grey", marginLeft: "5px" }}
            >
              ({row.rating_count})
            </span>
          )}
        </Box>
      ),
    },
    {
      field: "max_created",
      headerName: "last_validation",
      width: 120,
      renderCell: ({ row }) => {
        if (!row.max_created) return "";

        return fromNow(row.max_created);
      },
    },
    {
      field: "crm",
      renderCell: ({ row }) => (
        <Link
          href={`https://genz.dealcloud.com/portal/pages/3541/reports/3365/entries/${row.uid}`}
          target="_blank"
          style={{
            cursor: "pointer",
            textDecoration: "none",
            textTransform: "uppercase",
            color: "white",
          }}
        >
          <LaunchIcon style={{ fontSize: "1.1em" }} />
        </Link>
      ),
    },
    {
      field: "notes",
      width: 175,
      editable: true,
      flex: 1,
      renderEditCell: (params) => <EditTextarea {...params} />,
    },
    {
      field: "comments",
      headerName: "",
      width: 60,
      renderCell: ({ row }) => (
        <Box>
          <IconButton
            onClick={() => handleCommentClick(row)}
            // size='small'
          >
            <Badge
              badgeContent={row.comment_count}
              color="warning"
              sx={{
                "& .MuiBadge-badge": { fontSize: 10, height: 15, minWidth: 15 },
              }}
            >
              <ForumIcon fontSize="small" color="info" />
            </Badge>
          </IconButton>
        </Box>
      ),
    },
  ];

  function getInitialState() {
    // const savedColumnOrder = localStorage.getItem("columnOrder");
    // // console.log(savedColumnOrder);
    // const columnOrder = savedColumnOrder ? JSON.parse(savedColumnOrder) : [];
    // console.log(columnOrder);
    const columnOrder = [
      "products",
      "analyst",
      "client",
      "week",
      "day",
      "label",
      "last_1_days",
      "last_7_days",
      "last_30_days",
      "last_90_days",
      "total_validations",
      "total_validations_pct",
      "search_status",
      "type",
      "next_due_date",
      "from_now",
      "services",
      "end_customer",
      "customers",
      "geographies",
      "rating",
      "max_created",
      "crm",
      "notes",
      "comments",
    ];

    return {
      pagination: { paginationModel: { pageSize: 10 } },
      sorting: { sortModel: [{ field: "last_1_days", sort: "desc" }] },
      rowGrouping: { model: ["analyst"] },
      pinnedColumns: { right: ["comments"] },
      aggregation: {
        model: {
          last_1_days: "sum",
          last_7_days: "sum",
          last_14_days: "sum",
          last_30_days: "sum",
          last_90_days: "sum",
          last_180_days: "sum",
          last_365_days: "sum",
          this_week: "sum",
          last_week: "sum",
          total_validations: "sum",
        },
      },
      filter: {
        filterModel: {
          items: [
            {
              field: "search_status",
              operator: "is",
              value: "active",
            },
            {
              field: "last_90_days",
              operator: ">",
              value: 0,
            },
          ],
        },
      },

      columns: {
        columnVisibilityModel: {
          next_due_date: false,
          from_now: false,
          max_created: false,
          rating: false,
          crm: false,
          client: false,
          notes: false,
          total_validations_pct: false,
        },
      },
    };
  }

  const apiRef = useGridApiRef();

  const handleColumnOrderChange = () => {
    // console.log(apiRef.current);
    // console.log(apiRef.current.getAllColumns());
    const columnOrder = apiRef.current
      .getAllColumns()
      .map((column) => column.field);
    console.log(columnOrder);
    localStorage.setItem("searchesColumnOrder", JSON.stringify(columnOrder));
  };

  return (
    <Box
      sx={{
        "& .number-is-zero": {
          color: "#4E4B4B",
        },
      }}
    >
      <DataGridPremium
        apiRef={apiRef}
        rows={searches}
        columns={columns}
        getRowId={(row) => row.uid}
        density="compact"
        autoHeight
        // autoPageSize
        processRowUpdate={handleUpdate}
        onProcessRowUpdateError={(error, row, field, newValue) => {
          console.log(error, row, field, newValue);
        }}
        slots={{
          toolbar: GridToolbar,
        }}
        initialState={getInitialState()}
        pagination
        pageSizeOptions={[5, 10, 20, 50, 100]}
        unstable_cellSelection
        experimentalFeatures={{ clipboardPaste: true }}
        unstable_ignoreValueFormatterDuringExport
        // onColumnOrderChange={(params, event, details) => console.log(params, event, details)}
        onColumnOrderChange={handleColumnOrderChange}
        sx={{
          "& .MuiDataGrid-aggregationColumnHeaderLabel": {
            color: "white",
            opacity: 0.6,
            fontSize: "10px",
          },
          "& .MuiDataGrid-aggregationColumnHeader": {
            minHeight: "50px",
          },
          "& .MuiDataGrid-footerCell": {
            color: "secondary.main",
          },
        }}
      />

      <SearchCommentsDrawer
        searchData={search}
        open={showComments}
        setOpen={setShowComments}
      />
    </Box>
  );
}
