import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2", // Light blue for better readability
    },
    secondary: {
      main: "#FB5A45", // Vivid blue for better contrast
    },
    auto: {
      main: "#04FFFF",
    },
    info: {
      main: "#bdbdbd",
    },
    darkgray: {
      main: "#6B6B6B",
    },
    success: {
      main: "#4CC2A7",
    },
    error: {
      main: "#BC2B20",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: "white", // This will make text of all primary buttons white
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          colorScheme: "dark",
        },
      },
    },
  },
});

export default theme;
