import axios from "axios";
import React, { useEffect, useState } from "react";

import AppBar from "@mui/material/AppBar";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";

import SearchCommentsContainer from "../../components/SearchCommentsContainer";


import { clearColumnOrder } from "../../utils";

import { API_BASE } from "../../App.js";

export default function NoteDrawer({ searchData, open, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [showSave, setShowSave] = useState(false);

  const [notes, setNotes] = useState("");
  const [sources, setSources] = useState("");

  const [products, setProducts] = useState("");
  const [services, setServices] = useState("");
  const [endCustomer, setEndCustomer] = useState("");
  const [geographies, setGeographies] = useState("");
  const [customColumns, setCustomColumns] = useState([]);

  const [businessType, setBusinessType] = useState("");
  const [size, setSize] = useState("");

  useEffect(() => {
    if (!searchData) return;

    setNotes(searchData.meta.notes || "");
    setProducts(searchData.meta.products || "");
    setServices(searchData.meta.services || "");
    setEndCustomer(searchData.meta.end_customer || "");
    setGeographies(searchData.meta.geographies || "");
    setSources(searchData.meta.sources || "");
    setBusinessType(searchData.meta.business_type || "");
    setSize(searchData.meta.size || "");
    setCustomColumns(searchData.meta.custom_columns || []);
  }, [searchData]);

  const handleSave = async () => {
    setLoading(true);
    const endpoint = API_BASE + "event";
    const response = await axios.post(endpoint, {
      search_uid: searchData.uid,
      type: "update",
      data: {
        notes: notes,
        products: products,
        services: services,
        end_customer: endCustomer,
        geographies: geographies,
        sources: sources,
        business_type: businessType,
        size: size,
        custom_columns: customColumns,
      },
      actor_key: localStorage.getItem("actorKey"),
      token: localStorage.getItem("accessToken"),
    });

    // await new Promise((r) => setTimeout(r, 2000));
    clearColumnOrder(searchData.uid);
    window.location.reload();
    setLoading(false);
  };

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={open}
      style={{ zIndex: 64 }}
    >
      <PageAppBar setOpen={setOpen} loading={loading} handleSave={handleSave} />
      <Box
        id="right-drawer body"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "800px",
          padding: "0 30px",
        }}
        sx={{ mb: 30 }}
      >
        {open && <SearchCommentsContainer searchData={searchData} />}

        <TextField
          id="outlined-multiline-static"
          label="Research Notes"
          helperText="Client thesis, products, services, geography"
          // variant='standard'
          multiline
          minRows={2}
          maxRows={8}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          sx={{ my: 4 }}
        />

        <TextField
          id="outlined-multiline-static"
          label="Sources"
          helperText="Sources reviewed"
          // variant='standard'
          multiline
          maxRows={8}
          value={sources}
          onChange={(e) => setSources(e.target.value)}
          style={{ marginBottom: "25px" }}
        />

        <Typography variant="h5" style={{ marginBottom: "25px" }}>
          {searchData?.meta["client"]} Requirements:
        </Typography>
        <Box sx={{ mb: 4 }}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={customColumns}
            freeSolo
            getOptionLabel={(option) => option}
            value={customColumns}
            onChange={(e, v) => setCustomColumns(v)}
            renderInput={(params) => (
              <TextField
                {...params}
                // variant="standard"
                label="Custom Columns"
                placeholder="Add custom columns"
              />
            )}
          />
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // marginBottom: "25px",
            flexWrap: "wrap",
          }}
        >
          <TextField
            id="outlined-multiline-static"
            label="Business Type"
            helperText="Manufacturer, Distributor, Service, etc."
            value={businessType}
            select
            onChange={(e) => setBusinessType(e.target.value)}
            style={{ width: "350px", marginBottom: "25px" }}
          >
            <MenuItem value={"Manufacturer"}>Manufacturer</MenuItem>
            <MenuItem value={"Distributor"}>Distributor</MenuItem>
            <MenuItem value={"Service"}>Service</MenuItem>
          </TextField>
          <TextField
            id="outlined-multiline-static"
            label="Size"
            helperText="metrics such as revenue, employees, # reviews"
            value={size}
            multiline
            onChange={(e) => setSize(e.target.value)}
            style={{ width: "350px", marginBottom: "25px" }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Products"
            helperText="csv format"
            value={products}
            multiline
            onChange={(e) => setProducts(e.target.value)}
            style={{ width: "350px", marginBottom: "25px" }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Services"
            helperText="csv format"
            multiline
            value={services}
            onChange={(e) => setServices(e.target.value)}
            style={{ width: "350px", marginBottom: "25px" }}
          />

          <TextField
            id="outlined-multiline-static"
            label="End Customer"
            helperText="csv format"
            value={endCustomer}
            multiline
            onChange={(e) => setEndCustomer(e.target.value)}
            style={{ width: "350px", marginBottom: "25px" }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Geographies"
            helperText="csv format"
            value={geographies}
            multiline
            onChange={(e) => setGeographies(e.target.value)}
            style={{ width: "350px", marginBottom: "25px" }}
          />
        </Box>

        {/* {console.log(customColumns)} */}
      </Box>
    </Drawer>
  );
}

function PageAppBar({ setOpen, loading, handleSave }) {
  return (
    <AppBar position="sticky" variant="transparent" sx={{ mb: 4 }}>
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={loading}
            startIcon={<SaveIcon />}
            style={{ borderRadius: "20px" }}
          >
            Save
          </Button>
          {/* <IconButton onClick={handleSave} disabled={loading} color='primary'>
            <SaveIcon />
          </IconButton> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
