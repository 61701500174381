import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Chip,
  IconButton,
  Link,
  Switch,
  Toolbar,
  Typography
} from "@mui/material";

import { AddCircle, Description, Search, Timeline } from "@mui/icons-material";



import GrataDrawer from "./GrataDrawer";
import NotesDrawer from "./NotesDrawer";
import EventHistoryDrawer from "./EventHistoryDrawer";

import logo from "../../images/logo.svg";

import { ICON_MAP, LABEL_MAP } from "../../fragments/Constants";
import { getCompactState } from "../../utils";

export default function PageAppBar({ searchData, currentView }) {
  const navigate = useNavigate();
  const compact = getCompactState();
  
  const [noteDrawerOpen, setNoteDrawerOpen] = useState(false);
  const [grataOpen, setGrataOpen] = useState(false);
  const [eventHistoryOpen, setEventHistoryOpen] = useState(false);

  const toggleCompact = () => {
    localStorage.setItem("compact", JSON.stringify(!compact));
    window.location.reload();
  };

  return (
    <AppBar variant="transparent">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box
          id="left-side"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            pl: 20,
          }}
        >
          <Link href="/">
            <img src={logo} style={{ width: "200px" }} />
          </Link>

          <Switch
            checked={JSON.parse(localStorage.getItem("compact")) || false}
            onChange={toggleCompact}
            inputProps={{ "aria-label": "controlled" }}
            sx={{ ml: 10 }}
          />
        </Box>
        <Typography variant="body" style={{ textTransform: "uppercase" }}>
          {" "}
          {searchData?.label}
        </Typography>

        {
          <Chip
            label={LABEL_MAP[currentView]}
            style={{ marginLeft: "15px" }}
            size="large"
            variant="outlined"
            icon={ICON_MAP[currentView]}
          />
        }

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box id="import-targets" style={{ margin: "0 25px" }}>
            <IconButton
              color="info"
              onClick={() => navigate(`/import?searchKey=${searchData.uid}`)}
            >
              <AddCircle />
            </IconButton>
          </Box>

          <Box id="grata-search" style={{ margin: "0 25px" }}>
            <IconButton color="info" onClick={() => setGrataOpen(!grataOpen)}>
              <Search />
            </IconButton>
            <GrataDrawer
              searchData={searchData}
              open={grataOpen}
              setOpen={setGrataOpen}
            />
          </Box>

          <Box id="event-history" style={{ margin: "0 25px" }}>
            <IconButton color="info" onClick={() => setEventHistoryOpen(!grataOpen)}>
              <Timeline />
            </IconButton>
            <EventHistoryDrawer
              searchData={searchData}
              open={eventHistoryOpen}
              setOpen={setEventHistoryOpen}
            />
          </Box>


          <Box style={{ margin: "0 25px" }}>
            <IconButton
              color="info" // color="secondary" if auto ready
              onClick={() => setNoteDrawerOpen(!noteDrawerOpen)}
            >
              <Description />
            </IconButton>
            <NotesDrawer
              searchData={searchData}
              open={noteDrawerOpen}
              setOpen={setNoteDrawerOpen}
            />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
