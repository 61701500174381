import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { Close, Timeline, Undo } from "@mui/icons-material";

import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

import { API_BASE } from "../../App";

export default function EventHistoryDrawer({ searchData, open, setOpen }) {
  const [events, setEvents] = useState([]);

  const undoEvent = async (event_id) => {
    console.log("undoing event...");
    const endpoint = API_BASE + "mute_event";
    const response = await axios.post(endpoint, {
      search_uid: searchData.uid,
      event_id: event_id,
      actor_key: localStorage.getItem("actorKey"),
      token: localStorage.getItem("accessToken"),
    });
    window.location.reload();
  };

  const fetchEvents = async () => {
    console.log("fetching events...");
    const endpoint = API_BASE + "events";
    const response = await axios.get(endpoint, {
      params: { search_uid: searchData.uid },
    });

    setEvents(response.data);
  };

  useEffect(() => {
    if (open && searchData) {
      fetchEvents();
    }
    
  }, [open, searchData]);

  const columns = [
    {
      field: "undo",
      width: 60,
      renderCell: (params) =>
        params.row.actor_key === localStorage.getItem("actorKey") &&
        params.row.event_type !== "update" && (
          <IconButton onClick={() => undoEvent(params.row.id)}>
            <Undo color='warning'/>
          </IconButton>
        ),
    },
    {
      field: "search_label",
      width: 150,
    },
    {
      field: "search_uid",
      width: 120,
    },
    {
      field: "domain",
      width: 150,
    },
    {
      field: "event_type",
      width: 120,
    },
    {
      field: "created",
      width: 240,
    },
    {
      field: "data",
      width: 300,
      renderCell: (params) =>
        // <JSONPretty data={params.row.data} style={{ fontSize: '0.8em' }} />
        JSON.stringify(params.row.data),
    },
    {
      field: "actor_type",
      width: 130,
    },
    { field: "name", width: 130 }
    
  ];

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={open}
      style={{ zIndex: 64 }}
    >
      <AppBar position="static" variant="transparent">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Timeline color="secondary" />
            <Typography variant="h6" component="div">
              {searchData?.label} Timeline
            </Typography>
          </Box>
          <Box></Box>
        </Toolbar>
      </AppBar>

      <Box style={{ width: 1200 }} sx={{p: 4}}>
        <DataGridPremium
          getRowId={(row) => row.id}
          rows={events}
          columns={columns}
          density="compact"
          autoHeight
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            columns: {
              columnVisibilityModel: {
                search_label: false,
                search_uid: false,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          pagination
          pageSizeOptions={[10, 20, 50, 100]}
        />
      </Box>
    </Drawer>
  );
}
