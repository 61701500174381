import React from "react";

import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";

import { ArrowBackIos, DataUsage } from "@mui/icons-material";

import Consistency from "./Consistency";
import Leaderboard from "./Leaderboard";
import Searches from "./Searches";
import Time from "./Time";

export default function Reporting({}) {
  const actorKey = localStorage.getItem("actorKey");
  const ADMINS = [
    "7138248581",
    "3109166195",
    "3125740050",
    "skidder@genzassociates.com",
  ];

  return (
    <Box sx={{ pb: 10 }}>
      <PageAppBar />
      <Leaderboard />

      {ADMINS.includes(actorKey) && (
        <>
          <Consistency />
          <Time />
        </>
      )}

      <Searches />
    </Box>
  );
}

function PageAppBar() {
  return (
    <AppBar position="static" color="transparent">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => window.history.back()}>
          <ArrowBackIos />
        </IconButton>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <DataUsage color="secondary" />
          <Typography variant="h5">TargetSelect Reporting</Typography>
        </Box>
        <Box />
      </Toolbar>
    </AppBar>
  );
}
