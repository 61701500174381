import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Box,
  TextField,
  IconButton,
} from "@mui/material";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import CommentCard from "./CommentCard";

import { API_BASE } from "../../App.js";

export default function SearchCommentsContainer({ searchData }) {
  const [comments, setComments] = useState(null);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchAuto, setSearchAuto] = useState("");

  const submitComment = async () => {
    setLoading(true);
    const endpoint = API_BASE + "event";
    const response = await axios.post(endpoint, {
      search_uid: searchData.uid,
      actor_key: localStorage.getItem("actorKey"),
      type: "comment",
      token: localStorage.getItem("accessToken"),
      data: {
        comment: comment,
      },
    });
    setComment("");
    fetchComments();
    await fetchSearchAuto();
    setLoading(false);
  };

  const deleteComment = async (comment_id) => {
    try {
      setComments(comments.filter((comment) => comment["id"] !== comment_id));
      const endpoint = API_BASE + "comment_delete";
      const response = await axios.post(endpoint, {
        comment_id: comment_id,
        token: localStorage.getItem("accessToken"),
        actor_key: localStorage.getItem("actorKey"),
      });
      fetchComments();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchComments = async () => {
    const endpoint = API_BASE + "comments";
    const response = await axios.get(endpoint, {
      params: {
        search_uid: searchData.uid,
        actor_key: localStorage.getItem("actorKey"),
        token: localStorage.getItem("accessToken"),
      },
    });
    const comments = response.data.comments;
    const sortedComments = comments.sort((a, b) => {
      return new Date(a.created) - new Date(b.created);
    });
    setComments(sortedComments);
  };

  const fetchSearchAuto = async () => {
    if (!searchData) return; // Exit if drawer is not open
    setLoading(true);
    try {
      const endpoint = API_BASE + "search_auto";
      const response = await axios.get(endpoint, {
        params: {
          search_uid: searchData.uid,
          actor_key: localStorage.getItem("actorKey"),
          token: localStorage.getItem("accessToken"),
        },
      });
      // Assume there's a 'summary' field in the response
      setSearchAuto(response.data);
    } catch (error) {
      console.error("Failed to fetch search history:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchData) {
      fetchComments();
      fetchSearchAuto();
    }
  }, [searchData]);
  return (
    <>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            sx={{ mb: 1 }}
          >
            <AutoAwesomeIcon sx={{ mr: 2 }} color="warning" />
            <Typography variant="h5">SearchGPT Summary:</Typography>
          </Box>

          {loading && <CircularProgress />}
          {!loading && (
            <>
              <Typography variant="body1">{searchAuto?.text}</Typography>
              <Typography variant="body1" color="lightgray" sx={{ mt: 2, mb: 0 }}>
                Suggested keywords: 
              </Typography>
              <Typography variant="caption" color="lightgray" sx={{ my: 2 }}>
                {searchAuto?.suggested_keywords?.join(", ")}
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          {comments?.map((comment, index) => (
            <Box key={index}>
              <CommentCard comment={comment} deleteComment={deleteComment} />
            </Box>
          ))}
          <Box
            sx={{ my: 4 }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{
                mr: 2,
                "& .MuiOutlinedInput-root": { borderRadius: "30px" },
              }}
              style={{ width: "400px" }}
              placeholder="Add a comment..."
              // helperText="Ctrl + Enter to submit. Comments will be sent to SearchGPT"
              value={comment}
              disabled={loading}
              multiline
              size="small"
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={(e) => {
                if (e.ctrlKey && e.key === "Enter") {
                  submitComment();
                }
              }}
            />
            <IconButton
              style={{ background: "#1976d2" }}
              onClick={submitComment}
              disabled={loading}
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
