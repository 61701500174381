import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

import { AutoAwesome } from "@mui/icons-material";

import LeftDrawer from "./LeftDrawer";
import PageAppBar from "./PageAppBar";
import PromptContainer from "./PromptContainer";
import TargetCardContainer from "./TargetCardContainer";
import TargetTableContainer from "./TargetTableContainer";

import ErrorAlert from "../../fragments/ErrorAlert";

import { API_BASE } from "../../App";
import { getCompactState } from "../../utils";

export default function SearchResults() {
  const searchUid = useSearchParams()[0].get("searchKey"); // changing this requires updating/breaking prior sent Urls
  const currentView = useSearchParams()[0].get("currentView", "create");
  const [loading, setLoading] = useState(true);
  const [countLoading, setCountLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchData, setSearchData] = useState(null);
  const [targets, setTargets] = useState([]);
  const [targetCount, setTargetCount] = useState({});

  // display stuff

  const compact = getCompactState();

  useEffect(() => {
    getSearchData();
  }, []);

  useEffect(() => {
    if (searchData) {
      getTargets();
      fetchTargetCount();
    }
  }, [searchData]);

  const helmetTitle = () => {
    let title = searchData?.label;
    if (window.location.origin.includes("localhost")) {
      title = "[dev] " + title;
    } else if (window.location.origin.includes("staging")) {
      title = "[staging] " + title;
    }
    return title;
  };

  const getSearchData = async (e) => {
    try {
      const endpoint = API_BASE + "search";
      const response = await axios.get(endpoint, {
        params: {
          search_uid: searchUid,
          actor_key: localStorage.getItem("actorKey"),
          // token: localStorage.getItem("accessToken"),
        },
      });
      setSearchData(response["data"]);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  const getTargets = async (e) => {
    try {
      setError(null);
      setLoading(true);
      setTargets([]);
      const endpoint = API_BASE + "target";
      const response = await axios.get(endpoint, {
        params: {
          search_uid: searchData["uid"],
          stage: currentView,
          sortField: localStorage.getItem("sortField", "updated"),
          sortOrder: localStorage.getItem("sortOrder", "desc"),
          actor_key: localStorage.getItem("actorKey"),
          // token: localStorage.getItem("accessToken"),
        },
      });
      setTargets(response.data["target"]);
      // setTargetCount(response.data["counts"]);
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTargetCount = async () => {
    setCountLoading(true);
    try {
      const endpoint = API_BASE + "target/count";
      const response = await axios.get(endpoint, {
        params: {
          search_uid: searchData["uid"],
          actor_key: localStorage.getItem("actorKey"),
          // token: localStorage.getItem("accessToken"),
        },
      });
      setTargetCount(response.data);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
    setCountLoading(false);
  };

  const triggerRefresh = async () => {
    setLoading(true);

    const userConfirmed = confirm(
      "Reruns enrichment process in background. If you just imported the companies, they are already in process. Refresh page over coming minutes to see changes."
    );

    if (userConfirmed) {
      try {
        const endpoint = `${API_BASE}/enrich_stage`;
        const response = await axios.get(endpoint, {
          params: {
            search_uid: searchData.uid,
            stage: currentView,
          },
        });

        // Handle response if necessary
        console.log(response.data);
      } catch (error) {
        // Handle error if necessary
        console.error("Error fetching data:", error);
      }
    }

    setLoading(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Helmet>
        <title>{helmetTitle()}</title>
      </Helmet>

      <PageAppBar searchData={searchData} currentView={currentView} />

      <Box sx={{ mt: 8, display: "flex", flexDirection: "row" }}>
        <LeftDrawer
          searchData={searchData}
          currentView={currentView}
          targetCount={targetCount}
        />
        <Box
          id="targets-container"
          sx={{ mt: 1, pl: 20, pr: 8, maxWidth: "99vw" }}
        >
          {/* {loading && <LoadingSkeleton />} */}

          <Box sx={{ minHeight: "50px" }}>
            {!loading && targets.length < targetCount[currentView] && (
              <Box
                sx={{
                  mb: 2,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">
                  {targets.length} results ready
                </Typography>

                <Button
                  variant="outlined"
                  // color="info"
                  size="small"
                  startIcon={<AutoAwesome />}
                  onClick={triggerRefresh}
                  disabled={loading}
                  sx={{ ml: 2 }}
                >
                  Enrich remaining
                </Button>
              </Box>
            )}
          </Box>

          {compact && searchData && (
            <TargetTableContainer
              searchData={searchData}
              targets={targets}
              setTargets={setTargets}
              currentView={currentView}
              fetchTargetCount={fetchTargetCount}
            />
          )}

          {!compact && (
            <TargetCardContainer
              searchData={searchData}
              targets={targets}
              targetCount={targetCount}
              setTargets={setTargets}
              // handleTargetCount={handleTargetCount}
              fetchTargetCount={fetchTargetCount}
              currentView={currentView}
              loading={loading}
            />
          )}

          {
            currentView === "create" && (
              <PromptContainer searchData={searchData} currentView={currentView} />
            )
          }

          {/* <PromptContainer searchData={searchData} currentView={currentView} /> */}
        </Box>
      </Box>

      <ErrorAlert error={error} setError={setError} />
    </Box>
  );
}
