import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Typography,

  IconButton,
  Skeleton,
  Toolbar,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import { DataUsage, Whatshot } from "@mui/icons-material";

import AnalyticsMenu from "./AnalyticsMenu";
import BuyerTable from "./BuyerTable";
import CreateSearchContainer from "./CreateSearch";
import SearchBox from "./SearchBox";
import SearchesTable from "./SearchesTable";

import ErrorAlert from "../../fragments/ErrorAlert";
import Footer from "../../fragments/Footer";

import logo from "../../images/logo.svg";

import { API_BASE } from "../../App.js";

export default function Home() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searches, setSearches] = useState(null);
  const [buyers, setBuyers] = useState(null);
  const [open, setOpen] = useState(false);

  const getSearches = async (e) => {
    try {
      setLoading(true);
      const endpoint = API_BASE + "searches";
      const response = await axios.get(endpoint, {
        params: {
          actor_key: localStorage.getItem("actorKey"),
          token: localStorage.getItem("accessToken"),
        },
      });
      const searches = response["data"]["searches"];
      setSearches(searches);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getBuyers = async (e) => {
    try {
      setLoading(true);
      const endpoint = API_BASE + "buyer";
      const response = await axios.get(endpoint, {
        params: {
          actor_key: localStorage.getItem("actorKey"),
          token: localStorage.getItem("accessToken"),
        },
      });
      const buyers = response["data"];
      setBuyers(buyers);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      // setLoading(false);
    }
  };

  useEffect(async () => {
    getBuyers();
    await getSearches();
  }, []);

  return (
    <Box style={{ display: "flex", height: 800 }} sx={{ px: 4 }}>
      <ErrorAlert error={error} setError={setError} />
      <PageAppBar setOpen={setOpen} searches={searches} />

      <Box id="body" style={{ marginTop: 75, width: "100%", height: "500px" }}>
        {/* {loading && <CircularProgress />} */}
        <CreateSearchContainer open={open} setOpen={setOpen} />

        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
        >
          <Whatshot color="secondary" sx={{ fontSize: 24, mr: 1 }} />
          <Typography variant="h6">Active Searches</Typography>
        </Box>
        {searches && <SearchesTable searches={searches} />}
        {!searches && <Skeleton variant="rectangular" height={340} sx={{borderRadius: '5px'}} />}

        {buyers && (
          <Box style={{ marginTop: 50 }}>
            <BuyerTable buyers={buyers} />
          </Box>
        )}
        <Footer />
      </Box>
    </Box>
  );
}

function PageAppBar({ setOpen, searches }) {
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("actorKey");
    localStorage.removeItem("actorName");
    window.location.reload();
  };

  return (
    <AppBar position="fixed" variant="transparent" sx={{ px: 4 }}>
      <Toolbar sx={{ justifyContent: "space-between" }} disableGutters>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <img
            src={logo}
            style={{ width: "200px" }}
            onClick={() => (window.location.href = "/")}
          />
        </Box>
        {searches && <SearchBox searches={searches} />}
        <IconButton onClick={() => (window.location.href = "/reporting")}>
          <DataUsage color="secondary" />
        </IconButton>
        <AnalyticsMenu />
        <IconButton onClick={() => setOpen(true)}>
          <AddCircleIcon />
        </IconButton>
        <Button color="inherit" onClick={() => handleLogout()}>
          LOGOUT
        </Button>
      </Toolbar>
    </AppBar>
  );
}
