import React, { useState } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import { AutoAwesome } from "@mui/icons-material";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import SearchIcon from "@mui/icons-material/Search";

import Highlighter from "react-highlight-words";

import ChipRow from "./ChipRow";
import EventButtonsContainer from "./EventButtonsContainer";
import OpenDealCloudContainer from "./OpenDealCloudContainer";
import RatingContainer from "./RatingContainer";

import CommentsContainer from "../CommentsContainer";

import { ICON_MAP } from "../../../fragments/Constants";

export default function TargetCard({
  searchData,
  target,
  targets,
  setTargets,
  fetchTargetCount,
}) {
  const [name, setName] = useState(target["name"] || "");
  const [description, setDescription] = useState(
    target.meta["gpt_description"] || ""
  );

  const [display, setDisplay] = useState("");

  const contactString = () => {
    return [
      "contact_name",
      "contact_title",
      "contact_email",
      "contact_phone",
      "contact_address",
    ]
      .map((key) => {
        if (target[key] != "") {
          return (
            <span key={key}>
              {target[key]}
              {" • "}
            </span>
          );
        }
      })
      .join("");
  };

  const showContact = () => {
    return contactString() != "";
  };

  return (
    <>
      {true && (
        <Card
          variant="outlined"
          style={{
            marginBottom: "25px",
            padding: "15px",
            display: `${display}`,
          }}
          key={target["domain"]}
        >
          <CardContent style={{ paddingBottom: "5px" }}>
            <Box
              id="target-header"
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box id="company">
                <Link
                  href={`https://www.${target["domain"]}`}
                  target="_blank"
                  color="primary"
                >
                  {target["domain"]}
                </Link>
                <Box id="company-name">
                  <Typography variant="h5">{name}</Typography>
                </Box>
              </Box>
              <Box>
                <EventButtonsContainer
                  target={target}
                  searchData={searchData}
                  targets={targets}
                  setTargets={setTargets}
                  setDisplay={setDisplay}
                  fetchTargetCount={fetchTargetCount}
                />
              </Box>
            </Box>
            <ChipRow searchData={searchData} target={target} />

            {/* {!editing && searchData && (
          
        )} */}

            {target["was_acquired"] != "" && (
              <Box style={{ marginBottom: "10px", width: "100%" }}>
                <Typography variant="caption" color="lightgray">
                  was_acquired
                </Typography>
                <Typography variant="body" style={{ marginLeft: "15px" }}>
                  {target["was_acquired"]}
                </Typography>
                <Typography variant="caption" style={{ marginLeft: "15px" }}>
                  {target.meta["justification"]}
                </Typography>
              </Box>
            )}

            {showContact() && (
              <Box
                style={{
                  margin: "20px 5px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ContactEmergencyIcon color="info" />
                <Typography variant="body" style={{ marginLeft: "15px" }}>
                  {[
                    "contact_name",
                    "contact_title",
                    "contact_email",
                    "contact_phone",
                    "contact_address",
                  ].map((key) => {
                    if (target[key] != "") {
                      return (
                        <span key={key}>
                          {target[key]}
                          {" • "}
                        </span>
                      );
                    }
                  })}
                </Typography>
              </Box>
            )}

            <Box id="target-description" style={{ wordWrap: "break-word" }}>
              <AutoAwesome
                sx={{ fontSize: "0.8em", mr: 1 }}
                color="secondary"
              />
              <Highlighter
                // searchWords={keywords || []}
                searchWords={[]}
                autoEscape={true}
                textToHighlight={target.meta["gpt_description"] || ""}
                // highlightStyle={{ backgroundColor: '#F6A62D' }}
              />
            </Box>

            <OpenDealCloudContainer target={target} />
          </CardContent>

          <CardActions style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
            <RatingContainer
              target={target}
              targets={targets}
              setTargets={setTargets}
              setDisplay={setDisplay}
              fetchTargetCount={fetchTargetCount}
            />
            {/* <Box>
              {target.other_searches?.map((search, index) => (
                <Chip
                  key={index}
                  icon={ICON_MAP[search.stage]}
                  label={search.label}
                  variant="outlined"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    window.open(
                      `/search?searchKey=${search.search_uid}&currentView=${search.stage}`,
                      "_blank"
                    )
                  }
                />
              ))}
            </Box> */}
          </CardActions>

          <Box>
            <CommentsContainer searchData={searchData} target={target} />
          </Box>
        </Card>
      )}
    </>
  );
}
