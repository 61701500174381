import axios from "axios";
import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AutoAwesome from "@mui/icons-material/AutoAwesome";

import { API_BASE } from "../../App";
import { Typography } from "@mui/material";

export default function PromptContainer({ searchData, currentView }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");

  const examplePrompts = {
    grata: "Search grata for private hvac companies in Alabama with 100 or more employees",
    google: `You will search Google for the following the search terms:
        - residential deck contractors austin, tx
        - residential deck contractors san antonio, tx
        You will return the top 20 results for each search`,
    googleMaps: `You will search Google Maps for
        commercial valve distributors throughout Louisiana`,
    move: `Move these domains to client conflict:   
        amco.com
        google.com`,
  };

  const sendPrompt = async (e, v) => {
    try {
      setLoading(true);
      console.log(searchData.uid);
      const endpoint = API_BASE + "event";
      const eventResponse = await axios.post(endpoint, {
        search_uid: searchData.uid,
        actor_key: localStorage.getItem("actorKey"),
        type: "prompt",
        data: {
          prompt: prompt,
        },
      });
      // set 15 second timeout to reload page using Promise
      await new Promise((r) => setTimeout(r, 15000));
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSetPrompt = async (key) => {
    const cleanedVal = examplePrompts[key]
      .split("\n")
      .map((line) => line.trimStart())
      .join("\n");
    setPrompt(cleanedVal);
  };

  return (
    <div>
      <Box
        id="prompt-container"
        style={{
          display: "flex",
          flexDirection: "column",
          // maxWidth: '300px',
          padding: "15px 0",
          position: "fixed",
          bottom: "0",
          width: "100%",
          zIndex: "25",
          backgroundColor: "#121212",
        }}
      >
        <Typography variant="caption" style={{ color: "white", marginBottom: "15px" }}>
          Deliver to Inbox:
        </Typography>
        {/* autopilot */}
        <Box style={{ width: "100%", marginBottom: "15px" }}>
          <Button
            variant="text"
            onClick={() => handleSetPrompt("google")}
            startIcon={<AutoAwesome />}
            color="warning"
            size="small"
            style={{ marginRight: "15px" }}
          >
            GOOGLE
          </Button>
          <Button
            variant="text"
            onClick={() => handleSetPrompt("googleMaps")}
            startIcon={<AutoAwesome />}
            color="secondary"
            size="small"
            style={{ marginRight: "15px" }}
          >
            GOOGLE MAPS
          </Button>
          {/* <Button

            variant="text"
            onClick={() => handleSetPrompt("grata")}
            startIcon={<AutoAwesome />}
            color="primary"
            size="small"
            style={{ marginRight: "15px" }}
          >
            GRATA
          </Button> */}
        </Box>

        {/* prompt */}
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          {!loading && (
            <TextField
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              label="Enter Prompt"
              variant="outlined"
              size="small"
              style={{ width: "75%" }}
              onKeyDown={(e) => {
                if (e.ctrlKey && e.key === "Enter") {
                  handleSubmit(e);
                }
              }}
              InputProps={{
                style: {
                  borderRadius: "30px",
                },
              }}
              multiline
              maxRows={8}
            />
          )}

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginLeft: "25px",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <IconButton
                variant="contained"
                onClick={sendPrompt}
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                <ArrowUpwardIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
