import React, { useState } from "react";

import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { AccountBalance, Whatshot } from "@mui/icons-material";

import EditTextarea from "../../components/EditTextArea";

export default function BuyerTable({ buyers }) {
  const [showComments, setShowComments] = useState(false);
  const [target, setTarget] = useState(null);

  const handleCommentClick = async (row) => {
    setShowComments(!showComments);
    setTarget(row);
  };

  const columns = [
    {
      field: "change_date",
    },
    {
      field: "employees",
      type: "number",
      width: 140,
      // editable: true
    },
    {
      field: "domain",
      // width: 170,
      renderCell: ({ value }) => (
        <Link
          href={`https://www.${value}`}
          target="_blank"
          underline="hover"
          color="inherit"
        >
          {value}
        </Link>
      ),
    },
    {
      field: "products",
      width: 250,
      // editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  style={{ margin: "0 5px 5px 0" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "services",
      width: 250,
      // editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  style={{ margin: "0 5px 5px 0" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "end_customer",
      // editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  style={{ margin: "0 5px 5px 0" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "geographies",
      width: 250,
      // editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  style={{ margin: "0 5px 5px 0" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "name",
      width: 250,
    },
    {
      field: "description",
      width: 300,
      // editable: true,
      type: "string",
      renderEditCell: (params) => <EditTextarea {...params} />,
    },
  ];

  function getInitialState() {
    const defaultInitialState = {
      pagination: { paginationModel: { pageSize: 3, page: 0 } },
      sorting: { sortModel: [{ field: "change_date", sort: "desc" }] },
      filter: {
        filterModel: {
          items: [
            {
              field: "employees",
              operator: ">",
              value: 20,
            },
          ],
        },
      },
    };
    return defaultInitialState;
  }

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <AccountBalance color="success" sx={{ fontSize: 24, mr: 1 }} />
        <Typography variant="h6">Active Buyers</Typography>
      </Box>

      <DataGridPremium
        rows={buyers}
        columns={columns}
        getRowId={(row) => row.id}
        density="compact"
        autoHeight
        slots={{
          toolbar: GridToolbar,
        }}
        initialState={getInitialState()}
        pagination
        pageSizeOptions={[3, 5, 10, 20, 50, 100]}

        // unstable_cellSelection
        // experimentalFeatures={{ clipboardPaste: true }}
        // unstable_ignoreValueFormatterDuringExport
      />
    </Box>
  );
}
